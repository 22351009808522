import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { Table, Row, Col, Container } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css' 
import './headless-ecommerce.css'


class headlessEcommerce extends React.Component {
  render() {
    const siteTitle = "Headless Ecommerce"

    return (
      <Layout location={this.props.location} title="Traditional Vs Headless Commerce">
        <SEO title={siteTitle}/>
        <section id="swampoftics" className="component pb-0">
          <div className="container">
            <div className="row">
              <div className="col-md-10">
                <img className="img-fluid" src="/img/swamp.jpg" alt="swamp"/>
              </div>
            </div>
            <div className="content">
              <p>Websites-as-a-service (WSaaS) is an innovative model. WebriQ executes it with aplomb. Responsive and fast service; modern sense of design; robust and fast infrastructure; skilled multi-discipline coding. WebriQ understands the need for iteration to facilitate e-commerce activities. Very happy with our website situation.</p>
              <p className="quoteby text-light mb-0">Chris Cox</p>
              <p className="small text-white-50 mb-0">Swampfox</p>
            </div>
          </div>
        </section>
        
      <section id="truly-omnichannel" className="component">
        <Container>
            <Row className="align-items-center row justify-content-center">
              <Col md={6} className="ml-lg-auto">
                <div className="content">
                  <Row className="align-items-start justify-content-start">
                  <Col xs="auto">
                    <LazyLoadImage className="img-fluid" effect="blur" src="/img/offer-1.png" alt="3"/>
                  </Col>
                  <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                    <h5 className="font-weight-normal my-3">Truly omnichannel</h5>
                    <p>a headless content management system will help propel your content anywhere and everywhere. For an eCommerce brand, that means delivering your products, product videos or blog posts to any channel that has emerged — or will emerge.</p>
                  </Col>
                </Row>
                </div>
              </Col>
              <Col md={5}>
                <div className="text-center text-md-left">
                  <LazyLoadImage className="img-fluid" effect="blur" src="/img/icon-shopping-app.svg" alt="performance" width="380" height="336"/>
                </div>
              </Col>
            </Row>
          </Container>
      </section>
      <section id="rapid-deployments" className="component">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col md={5} className="text-center mb-4 mb-lg-0 order-2 order-md-1">
                <LazyLoadImage className="img-fluid" effect="blur" src="/img/icon-maker-launch.svg" alt="cost" width="400" height="306"/>
              </Col>
              <Col md={`${6} order-1 order-md-2 mb-4 mb-lg-0`}>
                <div className="content">
                  <Row className="align-items-start justify-content-md-end">
                    <Col xs="auto">
                      <LazyLoadImage className="img-fluid" effect="blur" src="/img/offer-2.png" alt="2"/>
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                      <h5 className="font-weight-normal my-3">Rapid deployments</h5>
                      <p>marketing tools such as email marketing, Google and Facebook advertising to grow your business faster</p>
                    </Col>
                  </Row>

                </div>

              </Col>
            </Row>
          </Container>
        </section>
        <section id="agile-marketing" className="component">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col md={6}  className="ml-lg-auto mb-4 mb-lg-0">
                <div className="content">
                  <Row className="align-items-start justify-content-start">
                    <Col xs="auto">
                      <LazyLoadImage className="img-fluid" effect="blur" src="/img/offer-3.png" alt="3"/>
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                      <h5 className="font-weight-normal my-3">Agile marketing</h5>
                      <p>a headless eCommerce system can support new technology as and when they arise. This is perfect for when designing new customer experiences. This puts marketing teams back into the driving seat where they can roll out multiple sites across different brands, divisions and portfolios.</p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={5}>
                <div className="text-center text-md-left">
                  <LazyLoadImage className="img-fluid" effect="blur" src="/img/icon-bear-market.svg" alt="agile marketing" width="400" height="337" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="customer-experience" className="component">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col md={5} className="text-center mb-4 mb-lg-0 order-2 order-md-1">
                <LazyLoadImage className="img-fluid" effect="blur" src="/img/icon-team-chat.svg" alt="customer experience" width="420" height="441"/>
              </Col>
              <Col md={`${6} order-1 order-md-2 mb-4 mb-lg-0`}>
                <div className="content">
                  <Row className="align-items-start justify-content-md-end">
                    <Col xs="auto">
                      <LazyLoadImage className="img-fluid" effect="blur" src="/img/offer-4.png" alt="4"/>
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                      <h5 className="font-weight-normal my-3">Consistent and personal customer experience</h5>
                      <p>even though customer needs change over time, they should still receive a consistent customer experience across all devices and channels.</p>
                    </Col>
                  </Row>

                </div>

              </Col>
            </Row>
          </Container>
        </section>
        <section id="seamless-integrations" className="component">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col md={6} className="ml-lg-auto mb-4 mb-lg-0">
                <div className="content">
                  <Row className="align-items-start justify-content-start">
                    <Col xs="auto">
                      <LazyLoadImage className="img-fluid" effect="blur" src="/img/offer-5.png" alt="5"/>
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                      <h5 className="font-weight-normal my-3">Seamless integrations</h5>
                      <p>by definition, a headless commerce solution must have an API, which makes it easier to integrate and communicate with other platforms, including larger, key infrastructure points such as ERPs, PIMs, OMS and POS.</p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={5}>
                <div className="text-center text-md-left">
                  <LazyLoadImage className="img-fluid" effect="blur" src="/img/icon-instant-information.svg" alt="content" width="440" height="423"/>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section id="faster-market" className="component">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col md={5} className="text-center order-2 order-md-1">
                <LazyLoadImage className="img-fluid" effect="blur" src="/img/icon-investment-data.svg" alt="faster time to market" width="400" height="341"/>
              </Col>
              <Col md={`${6} order-1 order-md-2 mb-4 mb-lg-0`}>
                <div className="content">
                  <Row className="align-items-start justify-content-md-end">
                    <Col xs="auto">
                      <LazyLoadImage className="img-fluid" effect="blur" src="/img/offer-6.png" alt="6"/>
                    </Col>
                    <Col xs={`${8} pl-0 pl-sm-3 pt-2`} lg={6} xl={7}>
                      <h5 className="font-weight-normal my-3">Faster time to market</h5>
                      <p>a headless commerce platform enables brands to focus on building front-end experiences on different devices and touchpoints, as the content and products are housed centrally and delivered via API to anywhere. This facilitates faster time to market when adopting new channels, or new regions</p>
                    </Col>
                  </Row>

                </div>

              </Col>
            </Row>
          </Container>
        </section>
        <section className="webriq-headless-commerce my-5 pt-5 d-none">
              <div className="container">
                  <Table responsive="md" striped bordered hover variant="light">
                    <thead>
                      <tr>
                        <th colspan="1"></th>
                        <th className="h5">TRADITIONAL</th>
                        <th className="h5">HEADLESS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Front-end Development</td>
                        <td>
                          <ol className="list-unstyled mb-0 checked">
                            <li>Design constraints</li>
                            <li>Time to edit the database, code, and platform is massive</li>
                          </ol>
                        </td>
                        <td>
                          <ol className="list-unstyled mb-0 checked">
                            <li>No design constraint. Just make a simple API call</li>
                            <li>Need to build front-end presentation from scratch</li>
                          </ol>
                        </td>
                      </tr>
                      <tr>
                        <td>Customization and Personalization</td>
                        <td>Pre-defined experience for both users and admins</td>
                        <td>Create your own experience for both users and admins</td>
                      </tr>
                      <tr>
                        <td>Flexibility and Adapbility</td>
                        <td>Front-end is tightly coupled with back-end, which means there's little room for customization</td>
                        <td>
                          <ol className="list-unstyled mb-0 checked">
                            <li>Endless customization</li>
                            <li>Changes can be made in the front-end</li>
                          </ol>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
              </div>
      </section>
      <section id="traditional-vs-headless" className="component pt-0">
        <Container>
          <Row>
            <Col sm={`${11} mx-auto`}>
              <LazyLoadImage effect="blur" className="img-fluid" src="/img/traditionalvsheadless.svg" alt="Traditional vs Headless"/>
            </Col>
          </Row>
        </Container>
      </section>
        <section id="headless-building-blocks" className="component">
            <Container>
              <Row>
                <Col xs={12} className="text-center">
                  <div className="building-blocks_header mb-5 mx-auto">
                    <h3 className="mt-0">Headless eCommerce Building Blocks</h3>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md={3}>
                  <div className="text-center blocks_content m-0">
                    <LazyLoadImage
                      effect="blur"
                      src="../img/blocks-sanity.png"
                      className="img-fluid"
                    />
                    <p>
                      delivering content to digital devices and products
                    </p>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="text-center blocks_content m-0">
                    <LazyLoadImage
                      effect="blur"
                      src="../img/block-gatsby-js.png"
                      className="img-fluid"
                    />
                    <p>blazing fast websites and and web apps</p>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="text-center blocks_content m-0">
                    <LazyLoadImage
                      effect="blur"
                      src="../img/block-bigcommerce.png"
                      className="img-fluid"
                    />
                    <p>powerful enterprise grade headless eCommerce platform</p>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="text-center blocks_content m-0">
                    <LazyLoadImage
                      effect="blur"
                      src="../img/blocks-netlify.png"
                      className="img-fluid"
                    />
                    <p>web projects at global scale—without servers, devops, or costly infrastructure</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

        <section id="download-whitepaper" className="component">
          <div className="container text-center">
            
            <a className="hvr-shadow btn btn-primary text-left" href="https://res.cloudinary.com/dnla85pdq/image/upload/v1594358349/webriq/files/headless-ecommerce.pdf" target="_blank" rel="noreferrer noopener">
              <div className="d-flex justify-content-start align-items-center"><img className="img-fluid mb-0 mr-3" width={18} height={18} src="/img/google-drive-pdf-file.svg" alt="pdf"/><span>Download White Paper - a new era for eCommerce</span></div>
            </a>
          </div>
        </section>
      </Layout>
    )
  }
}

export default headlessEcommerce